<template>
  <div>
    <div class="connect">
      <div class="inner">
        <div class="title">Connect to virtually any Payment Service</div>
        <div class="content margin-top-15">
          <div>Moozumi lets you leverage gateways, fraud tools, and other payment services in 100 currencies globally.<br/> Orchestrate the services you need for your payments strategy.</div>
        </div>
      </div>
    </div>
    <div class="service">
      <div class="inner">
        <template v-for="(item, index) in serviceList">
          <img :key="index" :src="require(`../../assets/images/connections_connections/${item}.png`)" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      serviceList: []
    }
  },
  created () {
    for (let i = 0; i < 25; i++) {
      this.serviceList.push(i + 1)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.connect {
  padding: 120px 0;
  background: url(../../assets/images/connections_connections/bg1.png);
  background-size: cover;
  .inner {
    width: 870px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    margin: 0 auto;
    .title {
      font-family: Helvetica-Bold;
      font-size: 52px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 68px;
    }
    .content {
      font-family: HelveticaNeue;
      font-size: 18px;
      color: $white;
      letter-spacing: 0;
      text-align: center;
      line-height: 36px;
    }
  }
}
.service {
  padding: 120px 0;
  background: $content_bg_color1;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    img {
      width: 224px;
    }
  }
}
@media screen and (max-width: 1200px) {
}
</style>
